import { defineComponent as _defineComponent } from 'vue'
import TierOneDetailInformation from "@/components/Common/FormHelpper/TierOneDetailInformation.vue";
import { ITenantPartner } from "@/models/Tenant";
import DialogModule from "@/store/UI/Dialog";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantPartnerDetail.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const dataRequest = DialogModule.getData as any;
const initialValues = dataRequest.tenantPartner as ITenantPartner;
const isViewBrandOwnerDetail = dataRequest.isViewBrandOwnerDetail as boolean;

const handleClose = () => {
  DialogModule.hideModal();
};

const __returned__ = { dataRequest, initialValues, isViewBrandOwnerDetail, handleClose, TierOneDetailInformation, get ITenantPartner() { return ITenantPartner }, get DialogModule() { return DialogModule } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})