import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusText',
  props: {
  content: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
    validator: (value: string) => ["view", "accept", "reject"].includes(value),
  },
},
  setup(__props) {

const props = __props;

// Dynamic class based on the status
const statusClass = computed(() => {
  switch (props.status) {
    case "view":
      return "text-green-secondary";
    case "accept":
      return "text-green-secondary";
    case "reject":
      return "text-red-primary";
    default:
      return "";
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", {
    class: _normalizeClass([statusClass.value, "font-bold"])
  }, _toDisplayString(__props.content), 3))
}
}

})