import { defineComponent as _defineComponent } from 'vue'
import TenantDetailForm from "@ems/containers/Tenant/TheForm/TenantDetail.Form.vue";
import BrandOwnerTable from "@ems/containers/BrandOwner/Table/index.vue";
import TierOneClient from "@ems/containers/TierOneClient/Table/index.vue";
import BOInformationForm from "@ems/containers/BrandOwner/TheForm/BOInformation.Form.vue";
import BODocumentsForm from "@ems/containers/BrandOwner/TheForm/BODocuments.Form.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { TenantDetailForm, BrandOwnerTable, TierOneClient, BOInformationForm, BODocumentsForm }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})