import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col pb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.country,
          content: $props.partnerInformation?.CountryName
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.province,
          content: $props.partnerInformation?.ProvinceName
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.company_name,
          content: $props.partnerInformation?.CompanyName
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.company_registration_number,
          content: $props.partnerInformation?.CompanyRegistrationNo
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.email,
          content: $props.partnerInformation?.Email
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.address,
          content: $props.partnerInformation?.Address
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.phone_code,
          content: $props.partnerInformation?.PhoneCode
        }, null, 8, ["label", "content"])
      ]),
      _createElementVNode("div", null, [
        _createVNode($setup["ReadOnlyField"], {
          label: $setup.SYSTEM.contact_number,
          content: $props.partnerInformation?.PhoneNumber
        }, null, 8, ["label", "content"])
      ])
    ])
  ]))
}