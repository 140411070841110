import { defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import {
  STATUS_TENANT,
  STATUS_INVITATION,
} from "@ems/constants/account_registration_status";
import { INVITATION_ACTION } from "@ems/constants/invitation_action";
import {
  ITenantPartnerParameters,
  ITenantUpdateInvitationStatusRequest,
} from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { formatDateTime } from "@/utils/helpers/convertDateTime";
import DialogModule from "@/store/UI/Dialog";
import TenantPartnerDetailForm from "@ems/containers/Tenant/TheForm/TenantPartnerDetail.Form.vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { getTenantIsActive } from "@/utils/storage";
import StatusText from "@/components/Common/Status/StatusText.vue";
import InvitationBadge from "@/components/Common/Status/InvitationBadge.vue";
import { SEARCH_TEXT } from "@ems/locales/search";
import SearchInput from "@/components/Common/SearchHelper/SearchInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();

const disabled = ref(false);
const small = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const searchText = ref("");
const sortBy = ref("");
const sortOrder = ref(1);
const isSearch = ref(false);
const saveInputSearch = ref("");

const router = useRouter();
const route = useRoute();
const isLoading = ref(false);
const store = useStore();

const tenantAccountList = computed(
  () => TenantUserModule.dataGetListTenantInvitorGetter
);

const paginationGet = computed(
  () => TenantUserModule.paginationBrandOwnerGetter
);

const isLoadingDataListTenant = computed(
  () => TenantUserModule.isLoadingDataListTenantGetter
);

const handleClearSearch = () => {
  searchText.value = "";
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
  pageSize.value = val;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
    IsGetBrandOwners: true,
  });
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
    IsGetBrandOwners: true,
  });
};

const handleSortChange = (event: any) => {
  sortBy.value = event.prop;
  sortOrder.value = event.order === "ascending" ? 0 : 1;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
    IsGetBrandOwners: true,
  });
};

const handleOpenModal = (row: any) => {
  DialogModule.showModal({
    component: TenantPartnerDetailForm,
    data: {
      tenantPartner: row,
      visible: true,
      isViewBrandOwnerDetail: true,
    },
  });
};

const handleSearchCompany = () => {
  isSearch.value = true;
  saveInputSearch.value = searchText.value.trim();
  currentPage.value = 1;
  if (saveInputSearch.value === "") {
    return;
  }
  getTenantPartner({
    Search: saveInputSearch.value,
    IsGetBrandOwners: true,
  });
};

const getTenantPartner = (params?: ITenantPartnerParameters) => {
  TenantUserModule.getListTenantPartnerAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
    OrderBy: "created_date",
    OrderByDirection: sortOrder.value ?? 1,
  });
};

const handleInvitationAction = async (invitationId: string, action: number) => {
  const isActive = getTenantIsActive() === "true";
  isLoading.value = true;

  const params: ITenantUpdateInvitationStatusRequest = {
    InvitationId: invitationId,
    Action: action,
  };

  await TenantUserModule.updateTenantInvitationStatusAction(params);
  setTimeout(() => {
    isLoading.value = false;
    const response = TenantUserModule.dataUpdateInvitationStatusGetter;

    if (response) {
      !isActive
        ? router.push({ name: "TenantCreate" })
        : ElMessage.success(
            `Invitation has been ${INVITATION_ACTION[
              action
            ].toLocaleLowerCase()}!`
          );
      getTenantPartner({
        Search: isSearch.value ? saveInputSearch.value : "",
        IsGetBrandOwners: true,
      });
    } else {
      ElMessage.error("Failed to accept invitation.");
    }
  }, 1000);
};

watch(searchText, (newVal) => {
  if (newVal.trim() === "") {
    isSearch.value = false;
    saveInputSearch.value = "";
    getTenantPartner({
      IsGetBrandOwners: true,
    });
  }
});

onMounted(() => {
  getTenantPartner({
    IsGetBrandOwners: true,
  });
});

const __returned__ = { disabled, small, currentPage, pageSize, searchText, sortBy, sortOrder, isSearch, saveInputSearch, router, route, isLoading, store, tenantAccountList, paginationGet, isLoadingDataListTenant, handleClearSearch, handleSizeChange, handleCurrentChange, handleSortChange, handleOpenModal, handleSearchCompany, getTenantPartner, handleInvitationAction, computed, onMounted, ref, watch, get useRouter() { return useRouter }, get useRoute() { return useRoute }, BaseSvgIcon, BaseSpinner, get STATUS_TENANT() { return STATUS_TENANT }, get STATUS_INVITATION() { return STATUS_INVITATION }, get INVITATION_ACTION() { return INVITATION_ACTION }, get ITenantPartnerParameters() { return ITenantPartnerParameters }, get ITenantUpdateInvitationStatusRequest() { return ITenantUpdateInvitationStatusRequest }, get TenantUserModule() { return TenantUserModule }, get formatDateTime() { return formatDateTime }, get DialogModule() { return DialogModule }, TenantPartnerDetailForm, get useStore() { return useStore }, get ElMessage() { return ElMessage }, get getTenantIsActive() { return getTenantIsActive }, StatusText, InvitationBadge, get SEARCH_TEXT() { return SEARCH_TEXT }, SearchInput }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})