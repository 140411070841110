import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "px-4 py-3 shadow-lg rounded-lg relative bg-white" }
const _hoisted_2 = { class: "mb-1" }
const _hoisted_3 = { class: "flex items-center justify-between" }
const _hoisted_4 = { class: "flex items-center py-4" }
const _hoisted_5 = { class: "flex items-center justify-center space-x-4" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = {
  key: 0,
  type: "button"
}
const _hoisted_8 = {
  key: 1,
  type: "button"
}
const _hoisted_9 = { class: "flex-grow text-center w-full" }

import { computed, onMounted, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import BaseSpinner from "@/components/BaseSpinner.vue";
import {
  STATUS_TENANT,
  STATUS_INVITATION,
} from "@ems/constants/account_registration_status";
import { ITenantParameters, ITenantPartnerParameters } from "@/models/Tenant";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { formatDateTime } from "@/utils/helpers/convertDateTime";
import { getEnumValue } from "@/utils/helpers/getEnumValue";
import TierOneDetailFormDialog from "@ems/containers/TierOneClient/TheDialog/TierOneDetail.vue";
import DialogModule from "@/store/UI/Dialog";
import TenantPartnerDetailForm from "@ems/containers/Tenant/TheForm/TenantPartnerDetail.Form.vue";
import StatusText from "@/components/Common/Status/StatusText.vue";
import InvitationBadge from "@/components/Common/Status/InvitationBadge.vue";
import { SEARCH_TEXT } from "@ems/locales/search";
import SearchInput from "@/components/Common/SearchHelper/SearchInput.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const disabled = ref(false);
const small = ref(false);
const currentPage = ref(1);
const pageSize = ref(10);
const activeFilter = ref("All");
const searchText = ref("");
const sortBy = ref("");
const sortOrder = ref(1);
const isSearch = ref(false);
const saveInputSearch = ref("");
const selectedTenantId = ref<string>();
const isTierOneDetailModalOpen = ref(false);

const router = useRouter();
const route = useRoute();
const tenantAccountList = computed(
  () => TenantUserModule.dataGetListTenantPartnerGetter
);

const paginationGet = computed(() => TenantUserModule.paginationTierOneGetter);

const isLoadingDataListTenant = computed(
  () => TenantUserModule.isLoadingDataListTenantGetter
);

const handleSendInvitation = () => {
  router.push(`${route.path}/send-invitation`);
};

const handleClearSearch = () => {
  searchText.value = "";
};

const openTierOneDetail = (_: number, tenantId: string) => {
  selectedTenantId.value = tenantId;
  isTierOneDetailModalOpen.value = true;
};

const handleSizeChange = (val: number) => {
  currentPage.value = 1;
  pageSize.value = val;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
  });
};

const handleCurrentChange = (val: number) => {
  currentPage.value = val;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
  });
};

const handleSortChange = (event: any) => {
  sortBy.value = event.prop;
  sortOrder.value = event.order === "ascending" ? 0 : 1;
  getTenantPartner({
    Search: isSearch.value ? saveInputSearch.value : "",
  });
};

const handleSearchCompany = () => {
  isSearch.value = true;
  saveInputSearch.value = searchText.value.trim();
  currentPage.value = 1;
  if (saveInputSearch.value === "") {
    return;
  }
  getTenantPartner({
    Search: saveInputSearch.value,
  });
};

const handleOpenModal = (row: any) => {
  DialogModule.showModal({
    component: TenantPartnerDetailForm,
    data: {
      tenantPartner: row,
      visible: true,
      isViewBrandOwnerDetail: false,
    },
  });
};

const getTenantPartner = (params?: ITenantPartnerParameters) => {
  TenantUserModule.getListTenantPartnerAction({
    ...params,
    CurrentPage: currentPage.value,
    Limit: pageSize.value,
    OrderBy: "created_date",
    OrderByDirection: sortOrder.value ?? 1,
  });
};

watch(searchText, (newVal) => {
  if (newVal.trim() === "") {
    isSearch.value = false;
    saveInputSearch.value = "";
    getTenantPartner();
  }
});

onMounted(() => {
  getTenantPartner();
});

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "text-lg font-semibold whitespace-nowrap text-neutral-dark" }, " Tier 1 ", -1)),
          _createElementVNode("button", {
            class: "btn btn--green-primary rounded-md px-4 py-2",
            onClick: _withModifiers(handleSendInvitation, ["prevent"])
          }, " Send Invitation ")
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(SearchInput, {
          class: "w-full",
          modelValue: searchText.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
          onSearch: handleSearchCompany,
          onClear: handleSearchCompany,
          placeholder: _unref(SEARCH_TEXT).search_by_company_name
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _withDirectives((_openBlock(), _createBlock(_component_el_table, {
        class: "w-full table-custom overflow-y-auto",
        style: {
        borderRadius: '4px',
        borderColor: 'transparent',
        color: '#000000',
      },
        onSortChange: handleSortChange,
        data: tenantAccountList.value,
        "max-height": "60vh",
        stripe: "",
        "cell-class-name": "text-cafe-dark",
        "header-row-class-name": "tableHeaderRow",
        "row-class-name": "tableRow",
        "element-loading-text": "Loading...",
        "element-loading-background": "rgba(0, 0, 0, 0.6)"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            "min-width": "80",
            prop: "CompanyName",
            label: "Company Name"
          }),
          _createVNode(_component_el_table_column, {
            prop: "CountryName",
            label: "Country",
            "min-width": "60"
          }),
          _createVNode(_component_el_table_column, {
            prop: "Email",
            label: "POC Email"
          }),
          _createVNode(_component_el_table_column, {
            prop: "SendDate",
            sortable: "",
            label: "Send Date",
            "header-align": "right",
            align: "right"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("p", null, _toDisplayString(_unref(formatDateTime)(scope.row.SendDate)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "TenantStatus",
            label: "Status",
            "header-align": "center",
            align: "center"
          }, {
            default: _withCtx((scope) => [
              _createVNode(InvitationBadge, {
                status: scope.row.InvitationStatus
              }, null, 8, ["status"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "Action",
            label: "Details",
            "header-align": "center"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("button", {
                  type: "button",
                  onClick: _withModifiers(($event: any) => (handleOpenModal(scope.row)), ["prevent"])
                }, [
                  _createVNode(StatusText, {
                    content: "View",
                    status: "view"
                  })
                ], 8, _hoisted_6),
                (scope.row.Status == _unref(STATUS_TENANT).Pending)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_7, [
                      _createVNode(StatusText, {
                        content: "Resend",
                        status: "accept"
                      })
                    ]))
                  : _createCommentVNode("", true),
                (scope.row.InvitationStatus == _unref(STATUS_INVITATION).Accepted)
                  ? (_openBlock(), _createElementBlock("button", _hoisted_8, [
                      _createVNode(StatusText, {
                        content: "Remove",
                        status: "reject"
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])), [
        [_directive_loading, isLoadingDataListTenant.value]
      ]),
      (tenantAccountList.value.length > 0)
        ? (_openBlock(), _createBlock(_component_el_pagination, {
            key: 0,
            "current-page": currentPage.value,
            "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
            "page-size": pageSize.value,
            "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((pageSize).value = $event)),
            "page-sizes": [10, 20, 30, 40],
            size: "small",
            disabled: disabled.value,
            background: false,
            layout: "sizes, slot, prev, pager, next",
            total: paginationGet.value.TotalRow,
            onSizeChange: handleSizeChange,
            onCurrentChange: handleCurrentChange,
            class: "py-2 font-semibold text-neutral-dark"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", null, " Show " + _toDisplayString((currentPage.value - 1) * pageSize.value + 1) + " to " + _toDisplayString(Math.min(currentPage.value * pageSize.value, paginationGet.value.TotalRow)) + " of " + _toDisplayString(paginationGet.value.TotalRow) + " Items ", 1)
              ])
            ]),
            _: 1
          }, 8, ["current-page", "page-size", "disabled", "total"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(TierOneDetailFormDialog, {
      "tenant-id": selectedTenantId.value,
      "is-tier-one-detail-modal-open": isTierOneDetailModalOpen.value,
      "onUpdate:isTierOneDetailModalOpen": _cache[3] || (_cache[3] = ($event: any) => ((isTierOneDetailModalOpen).value = $event)),
      "append-to-body": ""
    }, null, 8, ["tenant-id", "is-tier-one-detail-modal-open"])
  ], 64))
}
}

})