import { defineComponent as _defineComponent } from 'vue'
import RegistrationDocuments from "@/components/Common/FileHelper/RegistrationDocuments.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BODocuments.Form',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { RegistrationDocuments }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})