import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "px-4 py-3 shadow-lg rounded-lg relative bg-white" }
const _hoisted_2 = { class: "flex items-center py-4" }
const _hoisted_3 = { class: "flex items-center justify-center space-x-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick", "disabled"]
const _hoisted_6 = ["onClick", "disabled"]
const _hoisted_7 = { class: "flex-grow text-center w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "mb-1 flex justify-between justify-items-center gap-4" }, [
      _createElementVNode("div", { class: "flex space-x-8" }, [
        _createElementVNode("h1", { class: "text-lg font-semibold whitespace-nowrap text-neutral-dark" }, " Brand Owner ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode($setup["SearchInput"], {
        class: "w-full",
        modelValue: $setup.searchText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.searchText) = $event)),
        onSearch: $setup.handleSearchCompany,
        onClear: $setup.handleSearchCompany,
        placeholder: $setup.SEARCH_TEXT.search_by_company_name
      }, null, 8, ["modelValue", "placeholder"])
    ]),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      class: "w-full table-custom overflow-y-auto",
      style: {
        borderRadius: '4px',
        borderColor: 'transparent',
        color: '#000000',
      },
      onSortChange: $setup.handleSortChange,
      data: $setup.tenantAccountList,
      "max-height": "60vh",
      stripe: "",
      "cell-class-name": "text-cafe-dark",
      "header-row-class-name": "tableHeaderRow",
      "row-class-name": "tableRow",
      "element-loading-text": "Loading...",
      "element-loading-background": "rgba(0, 0, 0, 0.6)"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          "min-width": "80",
          prop: "CompanyName",
          label: "Company Name"
        }),
        _createVNode(_component_el_table_column, {
          "min-width": "60",
          prop: "CountryName",
          label: "Country"
        }),
        _createVNode(_component_el_table_column, {
          prop: "SendDate",
          sortable: "",
          label: "Send Date"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString($setup.formatDateTime(scope.row.SendDate)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "TenantStatus",
          label: "Status",
          "header-align": "center",
          align: "center"
        }, {
          default: _withCtx((scope) => [
            _createVNode($setup["InvitationBadge"], {
              status: scope.row.InvitationStatus
            }, null, 8, ["status"])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "Action",
          label: "Details",
          "header-align": "center"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                type: "button",
                onClick: _withModifiers(($event: any) => ($setup.handleOpenModal(scope.row)), ["prevent"])
              }, [
                _createVNode($setup["StatusText"], {
                  content: "View",
                  status: "view"
                })
              ], 8, _hoisted_4),
              (scope.row.InvitationStatus == $setup.STATUS_INVITATION.Sent)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    onClick: _withModifiers(($event: any) => (
                $setup.handleInvitationAction(
                  scope.row.InvitationId,
                  $setup.INVITATION_ACTION.Accept
                )
              ), ["prevent"]),
                    disabled: $setup.isLoading
                  }, [
                    _createVNode($setup["StatusText"], {
                      content: "Accept",
                      status: "accept"
                    })
                  ], 8, _hoisted_5))
                : _createCommentVNode("", true),
              (scope.row.InvitationStatus == $setup.STATUS_INVITATION.Sent)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    type: "button",
                    onClick: _withModifiers(($event: any) => (
                $setup.handleInvitationAction(
                  scope.row.InvitationId,
                  $setup.INVITATION_ACTION.Reject
                )
              ), ["prevent"]),
                    disabled: $setup.isLoading
                  }, [
                    _createVNode($setup["StatusText"], {
                      content: "Reject",
                      status: "reject"
                    })
                  ], 8, _hoisted_6))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [_directive_loading, $setup.isLoadingDataListTenant]
    ]),
    ($setup.tenantAccountList.length > 0)
      ? (_openBlock(), _createBlock(_component_el_pagination, {
          key: 0,
          "current-page": $setup.currentPage,
          "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => (($setup.currentPage) = $event)),
          "page-size": $setup.pageSize,
          "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => (($setup.pageSize) = $event)),
          "page-sizes": [10, 20, 30, 40],
          size: "small",
          disabled: $setup.disabled,
          background: false,
          layout: "sizes, slot, prev, pager, next",
          total: $setup.paginationGet.TotalRow,
          onSizeChange: $setup.handleSizeChange,
          onCurrentChange: $setup.handleCurrentChange,
          class: "py-2 font-semibold text-neutral-dark"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, " Show " + _toDisplayString(($setup.currentPage - 1) * $setup.pageSize + 1) + " to " + _toDisplayString(Math.min($setup.currentPage * $setup.pageSize, $setup.paginationGet.TotalRow)) + " of " + _toDisplayString($setup.paginationGet.TotalRow) + " Items ", 1)
            ])
          ]),
          _: 1
        }, 8, ["current-page", "page-size", "disabled", "total"]))
      : _createCommentVNode("", true)
  ]))
}