import { defineComponent as _defineComponent } from 'vue'
import CompanyInformation from "@/components/Common/FormHelpper/CompanyInformation.vue";
import ReadOnlyField from "@/components/Common/FormHelpper/ReadOnlyField.vue";
import { ITenantRegistration } from "@/models/Tenant";
import { getTenantId } from "@/utils/storage";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { SYSTEM } from "@ems/locales/system";
import { onMounted, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'BOInformation.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const tenantData = ref<ITenantRegistration>();

const fetchTenant = async (tenantId: string) => {
  await TenantUserModule.getTenantAccountRegistrationAction(tenantId ?? "");
  const dataRegistration = TenantUserModule.dataTenantRegistrationGetter;
  if (dataRegistration) {
    tenantData.value = { ...dataRegistration };
  }
};

onMounted(async () => {
  await fetchTenant(getTenantId()!);
});

const __returned__ = { tenantData, fetchTenant, CompanyInformation, ReadOnlyField, get ITenantRegistration() { return ITenantRegistration }, get getTenantId() { return getTenantId }, get TenantUserModule() { return TenantUserModule }, get SYSTEM() { return SYSTEM }, onMounted, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})