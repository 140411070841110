import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white shadow-lg rounded-lg px-4 py-3 text-neutral-dark w-full h-80" }
const _hoisted_2 = { class: "font-bold text-lg pb-3" }
const _hoisted_3 = { class: "flex gap-10 overflow-x-auto whitespace-nowrap mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString($setup.SYSTEM.registration_documents), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["FileDisplay"], {
        file: $setup.companyRegisFile,
        fileLabel: $setup.SYSTEM.company_registration_document
      }, null, 8, ["file", "fileLabel"]),
      _createVNode($setup["FileDisplay"], {
        file: $setup.letterOfAuthorFile,
        fileLabel: $setup.SYSTEM.letter_of_authorization
      }, null, 8, ["file", "fileLabel"]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.otherRelevantsFile, (file) => {
        return (_openBlock(), _createElementBlock("div", {
          key: file.name
        }, [
          _createVNode($setup["FileDisplay"], {
            file: file,
            fileLabel: $setup.SYSTEM.other_relevant_documents
          }, null, 8, ["file", "fileLabel"])
        ]))
      }), 128))
    ])
  ]))
}