import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dialog-title text-left text-xl font-bold text-neutral-dark" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    center: "",
    "before-close": $setup.handleClose,
    "show-close": true
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString($setup.isViewBrandOwnerDetail
            ? "Brand Owner Information"
            : "Tier 1 Information"), 1)
    ]),
    default: _withCtx(() => [
      _createVNode($setup["TierOneDetailInformation"], { "partner-information": $setup.initialValues }, null, 8, ["partner-information"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}