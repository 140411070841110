import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-10 py-8" }
const _hoisted_2 = { class: "flex flex-col gap-4 py-2" }
const _hoisted_3 = { class: "flex gap-2 items-stretch" }
const _hoisted_4 = { class: "flex-1 w-1/2 h-full" }
const _hoisted_5 = { class: "flex-1 w-1/2 h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode($setup["BOInformationForm"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["BODocumentsForm"])
        ])
      ]),
      _createVNode($setup["BrandOwnerTable"]),
      _createVNode($setup["TierOneClient"])
    ])
  ]))
}