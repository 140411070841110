import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full pl-80" }

import { ref, watch } from "vue";
import TenantDetailForm from "@ems/containers/Tenant/TheForm/TenantDetail.Form.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TierOneDetail',
  props: {
  tenantId: String,
  isTierOneDetailModalOpen: Boolean,
},
  emits: ["update:isTierOneDetailModalOpen"],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isOpen = ref(false);

const emitCloseDialog = () => {
  isOpen.value = false;
  emit("update:isTierOneDetailModalOpen", false);
};

watch(
  () => props.isTierOneDetailModalOpen,
  (newVal) => {
    isOpen.value = newVal;
  }
);

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: "Tier 1 Information",
    modelValue: isOpen.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((isOpen).value = $event)),
    "append-to-body": "",
    width: "80%",
    onClose: emitCloseDialog
  }, {
    title: _withCtx(() => _cache[1] || (_cache[1] = [
      _createElementVNode("div", { class: "custom-dialog-title" }, [
        _createElementVNode("h2", { class: "text-xl font-bold text-gray-700" }, "Tier 1 Information")
      ], -1)
    ])),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TenantDetailForm, {
          "tenant-id": __props.tenantId,
          "is-show-status-remark": false
        }, null, 8, ["tenant-id"])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})